
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.header-gradient {
    position: relative;
  }
  
  .header-gradient::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100px; /* Adjust this value to change the height of the gradient */
    background: linear-gradient(to bottom, rgba(187, 225, 250, 1), rgba(255, 255, 255, 1)); 
 }  

 @keyframes blink {
  0% { color: black; }
  50% { color: white; }
  100% { color: black; }
}
.blinking-icon {
  animation: blink 2s infinite;
}
.detail-enter {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
  
  .detail-enter-active {
    max-height: 100vh;
  }
  
  .detail-exit {
    max-height: 100vh;
  }
  
  .detail-exit-active {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }